<template>
  <tbb-card title="BOOKING SERVICE Summary">
    <b-card class="mt-2">
      <div
        v-b-tooltip.hover.top="'Change selected date'"
        class="d-flex flex-wrap font-medium-3 font-weight-bolder cursor-pointer selected-date"
        @click="backToFirstStep()"
      >
        <span class="mr-auto">{{ selectedDateTimeStore | dateUsLocale }}</span>
        <span>{{ selectedDateTimeStore | hourUsLocale }}</span>
      </div>
      <b-list-group flush>
        <b-list-group-item
          v-for="(service, index) in selectedServicesStore"
          :key="index"
          class="d-flex justify-content-between"
        >
          <div>
            <span class="font-weight-bolder">{{ service.name }}</span> <span class="text-muted text-nowrap">({{ service.duration }} min)</span>
          </div>
          <span>${{ service.price | price }}</span>
        </b-list-group-item>
      </b-list-group>
      <div class="total padding-list-group-item d-flex justify-content-between">
        <span>Total</span>
        <span class="text-right font-weight-bolder">${{ selectedServicesStore | sumPrice }}</span>
      </div>
    </b-card>
  </tbb-card>
</template>

<script>
import {
  BListGroup, BListGroupItem, VBTooltip, BCard,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import { BOOKING_FIRST_STEP_PAGE, STYLIST_PAGE } from '@/router/routes/routes-names'
import moment from 'moment'
import TbbCard from '../../sites/TbbCard.vue'

export default {
  filters: {
    sumPrice(selectedServices) {
      let price = 0
      selectedServices.forEach(service => {
        price += +service.price
      })
      return price.toFixed(2)
    },
    price(price) {
      return Number(price).toFixed(2)
    },
    dateUsLocale(date) {
      return moment(date, 'YYYY-MM-DD HH:mm').format('MMMM D, yyyy')
    },
    hourUsLocale(hour) {
      return moment(`${hour}`, 'YYYY-MM-DD H:mm').format('h:mm a')
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BListGroup,
    BListGroupItem,
    BCard,
    TbbCard,
  },
  data() {
    return {
      stylistPagePath: STYLIST_PAGE,
    }
  },
  computed: {
    ...mapState('BookingServiceStoreModule', {
      selectedServicesStore: state => state.selectedServices,
      selectedDateTimeStore: state => state.selectedDateTime,
    }),
    ...mapState('StylistPageStoreModule', {
      stylistStore: state => state.stylist,
    }),
  },
  watch: {
    selectedServicesStore: {
      deep: true,
      handler(newSelectedServicesStore) {
        if (!newSelectedServicesStore.length) {
          this.$router.push({ name: STYLIST_PAGE })
        }
      },
    },
  },
  methods: {
    ...mapActions('BookingServiceStoreModule', [
      'clearSelectedDateTime',
    ]),
    backToFirstStep() {
      this.clearSelectedDateTime()
      this.$cookies.remove('reserved_book_date')
      this.$cookies.remove('selected_datetime')
      this.$router.push({ name: BOOKING_FIRST_STEP_PAGE })
    },
  },
}
</script>
<style lang="scss" scoped>
@import "src/assets/scss/variables/_variables.scss";
  .selected-date{
    padding: 1rem 1.5rem 0.5rem;
    background-color: $primary;
    margin: -1.5rem -1.5rem 0.5rem;
    border-radius: 0.428rem 0.428rem 0 0;
    color: #fff;
  }
  .list-group-item{
    padding-left: 0;
    padding-right: 0;
    &:hover{
      background-color: #fff;
    }
  }
  .padding-list-group-item {
    padding: 0.75rem 0;
  }
  .total {
    border-top: 1px solid #d1b08f;
  }
</style>
