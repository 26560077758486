<template>
  <tbb-card title="Booking for">
    <validation-observer
      ref="refFormCustomerObserver"
    >
      <b-form
        id="reserve-appointment-form"
        slot-scope="{ validate }"
        @submit.prevent="bookAppointment(validate())"
      >
        <b-form-row
          class="mt-2"
        >
          <b-col
            cols="12"
            lg="4"
            xl
          >
            <!-- Field: Name -->
            <validation-provider
              name="Name"
              rules="required"
            >
              <b-form-group
                slot-scope="{ valid, errors }"
                label="Name"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="customer.name"
                  :state="errors[0] ? false : null"
                  size="lg"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            lg="4"
            xl
          >
            <b-form-group
              label="Last name"
              label-for="last-name"
            >
              <b-form-input
                id="last-name"
                v-model="customer.lastName"
                size="lg"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            lg="4"
            xl
          >
            <!-- Cell phone number -->
            <validation-provider
              name="Cell phone number"
              rules="regex:^(\+?[0-9]+)$|checkPhoneNumberFormat"
            >
              <b-form-group
                slot-scope="{ valid, errors }"
                label="Cell phone number"
                label-for="phone"
              >
                <b-form-input
                  id="phone"
                  v-model="customer.phone"
                  name="phone"
                  :state="errors[0] ? false : null"
                  size="lg"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-form-row>
        <b-form-row
          v-if="!selectedCard.id"
          class="mt-2"
        >
          <b-col
            v-if="stripeAccountEnabled && (stylistPolicies[CANCELLATION_POLICY] || stylistPolicies[NO_SHOW_POLICY])"
            cols="12"
            lg="6"
            xl="6"
          >
            <b-form-group
              label="Credit Card number"
              label-for="card-number"
            >
              <div
                v-if="stylistPolicies[CANCELLATION_POLICY] || stylistPolicies[NO_SHOW_POLICY]"
                id="card-number"
                class="form-control form-control-lg"
                :class="{'is-invalid': cardError.number.empty || cardError.number.message}"
              >
                <!--Stripe.js injects the Card Element-->
              </div>
              <div class="invalid-feedback">
                {{ cardError.number.message ? cardError.number.message : "Card number cannot be empty" }}
              </div>
            </b-form-group>
          </b-col>
          <b-col
            v-if="stripeAccountEnabled && (stylistPolicies[CANCELLATION_POLICY] || stylistPolicies[NO_SHOW_POLICY])"
            cols="6"
            lg="3"
            xl="3"
          >
            <b-form-group
              label="Card expiration date"
              label-for="card-expiry"
            >
              <div
                v-if="stylistPolicies[CANCELLATION_POLICY] || stylistPolicies[NO_SHOW_POLICY]"
                id="card-expiry"
                class="form-control form-control-lg"
                :class="{'is-invalid': cardError.expiry.message}"
              >
                <!--Stripe.js injects the Card Element-->
              </div>
              <div class="invalid-feedback">
                {{ cardError.expiry.message }}
              </div>
            </b-form-group>
          </b-col>
          <b-col
            v-if="stripeAccountEnabled && (stylistPolicies[CANCELLATION_POLICY] || stylistPolicies[NO_SHOW_POLICY])"
            cols="6"
            lg="3"
            xl="3"
          >
            <b-form-group
              label="CVC code"
              label-for="card-cvc"
            >
              <div
                v-if="stylistPolicies[CANCELLATION_POLICY] || stylistPolicies[NO_SHOW_POLICY]"
                id="card-cvc"
                class="form-control form-control-lg"
                :class="{'is-invalid': cardError.cvc.message}"
              >
                <!--Stripe.js injects the Card Element-->
              </div>
              <div
                v-if="cardError.cvc.message"
                id="credit-card-error"
                class="invalid-feedback"
              >
                {{ cardError.cvc.message }}
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row
          v-if="selectedCard.id && (stylistPolicies[CANCELLATION_POLICY] || stylistPolicies[NO_SHOW_POLICY])"
          class="mt-2"
        >
          <b-col cols="12">
            <b-alert
              variant="primary"
              show
            >
              <p class="px-2">
                You have provided information for credit card ending with {{ selectedCard.last4 }} during your recent booking!
                <span
                  class="cursor-pointer"
                  @click="clearSelectedCard()"
                >
                  click here to change your CC information
                </span>
              </p>
            </b-alert>
          </b-col>
        </b-form-row>
        <b-form-row
          class="mt-2"
        >
          <b-col cols="12">
            <!-- About your appointment -->
            <validation-provider
              name="About your appointment"
            >
              <b-form-group
                slot-scope="{ valid, errors }"
                label="About your appointment"
                label-for="notes"
              >
                <b-form-textarea
                  id="notes"
                  v-model="note"
                  name="notes"
                  :state="errors[0] ? false : null"
                  size="lg"
                  rows="4"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="(stylistPolicies[CANCELLATION_POLICY] || stylistPolicies[NO_SHOW_POLICY]) && stripeAccountEnabled"
            cols="12"
          >
            <b-alert
              variant="danger"
              show
            >
              <p class="px-2">
                Your card will be charged unless the service requested requires a deposit, service is completed, you don't show for your appointment or cancel within the specified timeframe.
              </p>
            </b-alert>
          </b-col>
          <b-col cols="12">
            <booking-service-cancellation-policy
              v-if="stylistPolicies[CANCELLATION_POLICY] && stripeAccountEnabled"
              :charge-fee="stylistPolicies[CANCELLATION_POLICY].value"
              :cancellation-time="stylistPolicies[HOURS_BEFORE_APPOINTMENT_POLICY].value"
            />
            <booking-service-no-show-policy
              v-if="stylistPolicies[NO_SHOW_POLICY] && stripeAccountEnabled"
              :charge-fee="stylistPolicies[NO_SHOW_POLICY].value"
            />
            <b-form-checkbox-group
              v-if="(stylistPolicies[CANCELLATION_POLICY] || stylistPolicies[NO_SHOW_POLICY]) && stripeAccountEnabled"
              v-model="policyAgreementValue"
              :options="policyAgreementOptions"
              name="policy-agreement"
              required
            />
          </b-col>

          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              variant="outline-secondary"
              @click="backToFirstStep()"
            >
              Back
            </b-button>
            <b-button
              variant="primary"
              type="submit"
              class="ml-1"
            >
              Book
            </b-button>
          </b-col>
        </b-form-row>
      </b-form>
    </validation-observer>
  </tbb-card>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  BFormCheckboxGroup, BFormInvalidFeedback, BFormRow, BFormGroup, BFormInput, BFormTextarea, BButton, BCol, BForm, BAlert,
} from 'bootstrap-vue'
import moment from 'moment'
import {
  mapActions, mapMutations, mapGetters, mapState,
} from 'vuex'
import { BOOKING_FIRST_STEP_PAGE } from '@/router/routes/routes-names'
import { loadStripe } from '@stripe/stripe-js/pure'
import BookingServiceCancellationPolicy from '@/components/booking/booking-service/BookingServiceCancellationPolicy.vue'
import BookingServiceNoShowPolicy from '@/components/booking/booking-service/BookingServiceNoShowPolicy.vue'
import {
  CANCELLATION_POLICY,
  HOURS_BEFORE_APPOINTMENT_POLICY,
  NO_SHOW_POLICY,
} from '@/dictionaries/stylistPolicyConfigDictionary'
import TbbCard from '../../sites/TbbCard.vue'

extend('checkPhoneNumberFormat', {
  validate(value) {
    // eslint-disable-next-line no-mixed-operators
    return value == null || value != null && value.length < 18
  },
  computesRequired: true,
  message: 'The Cell phone number field format is invalid',
})

export default {
  name: 'BookingServiceExtraInformation',
  components: {
    BookingServiceNoShowPolicy,
    BookingServiceCancellationPolicy,
    BFormInvalidFeedback,
    BFormCheckboxGroup,
    BFormRow,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BCol,
    BForm,
    BAlert,
    ValidationObserver,
    ValidationProvider,
    TbbCard,
  },
  data() {
    return {
      customer: {
        name: null,
        lastName: null,
        phone: null,
        email: null,
      },
      policyAgreementValue: [],
      policyAgreementOptions: [
        { text: 'I Agree with all above', value: true },
      ],
      stylistPolicies: {},
      note: null,
      stripe: null,
      elements: null,
      card: {
        number: null,
        expiry: null,
        cvc: null,
      },
      cardError: {
        number: {
          complete: false,
          message: null,
          empty: false,
        },
        expiry: {
          complete: false,
          message: null,
        },
        cvc: {
          complete: false,
          message: null,
        },
      },
      selectedCard: {
        id: null,
      },
      stripeAccountEnabled: false,
      NO_SHOW_POLICY,
      CANCELLATION_POLICY,
      HOURS_BEFORE_APPOINTMENT_POLICY,
    }
  },
  computed: {
    ...mapState('BookingServiceStoreModule', {
      availableDatesStore: state => state.availableDates,
      selectedDateTimeStore: state => state.selectedDateTime,
      selectedServicesStore: state => state.selectedServices,
      customerLoggedStore: state => state.customerLogged,
      stylistPoliciesStore: state => state.stylistPolicies,
      cardsListStore: state => state.cardsList,
    }),
    ...mapState('StylistPageStoreModule', {
      stylistStore: state => state.stylist,
    }),
    ...mapGetters('BookingServiceStoreModule', [
      'stylistPoliciesGetter',
    ]),
  },
  watch: {
    customerLoggedStore: {
      deep: true,
      handler(newCustomerLoggedData) {
        if (newCustomerLoggedData.response) {
          this.customer = { ...this.customer, ...newCustomerLoggedData.response }
          if (newCustomerLoggedData.response && newCustomerLoggedData.response.stripeCustomerId && this.stripeAccountEnabled) {
            this.fetchCustomerCardsList()
          }
        }
      },
    },
    stylistStore: {
      deep: true,
      immediate: true,
      async handler(newStylistData) {
        if (newStylistData.response && newStylistData.response.stripeId) {
          this.stripeAccountEnabled = newStylistData.response.stripeAccountEnabled
          await this.renderStripeElementCard(newStylistData.response.stripeId)
        }
      },
    },
    stylistPoliciesStore: {
      deep: true,
      handler(newStylistPolicies) {
        if (newStylistPolicies.response) {
          this.stylistPolicies = this.stylistPoliciesGetter
          if (!this.selectedCard.id && this.stylistStore.response.stripeId) {
            this.renderStripeElementCard(this.stylistStore.response.stripeId)
          }
        }
      },
    },
    cardsListStore: {
      deep: true,
      handler(newCardsList) {
        if (newCardsList.response) {
          this.selectedCard = { ...newCardsList.response[0] }
        }
      },
    },
    selectedCard: {
      deep: true,
      handler(newSelectedCard) {
        if (!newSelectedCard && this.stylistStore.response.stripeId) {
          this.renderStripeElementCard(this.stylistStore.response.stripeId)
        }
      },
    },
  },
  mounted() {
    this.fetchStylistPolicies()
  },
  async created() {
    await this.$auth.init()
    this.$auth.auth0Client.getUser().then(user => {
      this.customer.name = user?.given_name
      this.customer.lastName = user?.family_name
      this.customer.email = user?.email
    })
  },
  methods: {
    ...mapActions('BookingServiceStoreModule', [
      'clearSelectedDateTime',
      'saveAppointment',
      'createStripeCustomer',
      'addCardToCustomer',
      'fetchStylistPolicies',
      'fetchCustomerCardsList',
    ]),
    ...mapMutations('BookingServiceStoreModule', {
      setCreateCardPending: 'SET_CREATE_CARD_PENDING',
    }),
    checkIfCardDataIsComplete() {
      return this.cardError.number.complete && this.cardError.expiry.complete && this.cardError.cvc.complete
    },
    async bookAppointment(validate) {
      await validate.then(success => {
        if (success) {
          if (!this.stripeAccountEnabled || this.selectedCard.id || !(this.stylistPolicies[CANCELLATION_POLICY] || this.stylistPolicies[NO_SHOW_POLICY])) {
            this.saveAppointment({ ...this.prepareDataToSave(), stripeCardId: this.selectedCard.id })
          } else if (this.customerLoggedStore.response && this.customerLoggedStore.response.stripeCustomerId && this.checkIfCardDataIsComplete()) {
            this.setCreateCardPending(true)
            this.saveNewCardInAppointment()
          } else if (this.checkIfCardDataIsComplete()) {
            this.createStripeCustomer({
              name: this.customer.name,
              email: this.customer.email,
              lastName: this.customer.lastName,
              phone: this.customer.phone,
            }).then(() => {
              this.setCreateCardPending(true)
              this.saveNewCardInAppointment()
            })
          } else {
            this.cardError.number.empty = true
          }
        }
      })
    },
    prepareDataToSave() {
      return {
        customer: { ...this.customer },
        services: { ...this.selectedServicesStore },
        startsAt: moment(this.selectedDateTimeStore, 'yyyy-MM-DD HH:mm').format('yyyy-MM-DD HH:mm:ss'),
        note: this.note,
      }
    },
    backToFirstStep() {
      this.$router.push({ name: BOOKING_FIRST_STEP_PAGE })
    },
    clearSelectedCard() {
      this.selectedCard = {
        id: null,
      }
      this.renderStripeElementCard(this.stylistStore.response.stripeId)
    },
    saveNewCardInAppointment() {
      this.stripe.createToken(this.card.number)
        .then(result => {
          if (result.error) {
            this.setCreateCardPending(false)
            this.creditCardError = true
          } else {
            this.creditCardError = false
            this.addCardToCustomer(result.token.id).then(card => {
              this.saveAppointment({ ...this.prepareDataToSave(), stripeCardId: card.data.id })
            }).catch(() => {
              this.cardError.number.message = 'Invalid card number'
            })
          }
        })
    },
    async renderStripeElementCard(customerStripeId) {
      if (!this.selectedCard.id && (this.stylistPolicies[CANCELLATION_POLICY] || this.stylistPolicies[NO_SHOW_POLICY])) {
        this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY, { stripeAccount: customerStripeId })
        this.elements = this.stripe.elements()
        this.card.number = this.elements.create('cardNumber')
        this.card.number.mount('#card-number')
        this.card.number.on('change', event => {
          this.cardError.number.empty = event.empty
          this.cardError.number.message = event.error ? event.error.message : null
          this.cardError.number.complete = event.complete
        })

        this.card.expiry = this.elements.create('cardExpiry')
        this.card.expiry.mount('#card-expiry')
        this.card.expiry.on('change', event => {
          this.cardError.expiry.message = event.error ? event.error.message : null
          this.cardError.expiry.complete = event.complete
        })

        this.card.cvc = this.elements.create('cardCvc')
        this.card.cvc.mount('#card-cvc')
        this.card.cvc.on('change', event => {
          this.cardError.cvc.message = event.error ? event.error.message : null
          this.cardError.cvc.complete = event.complete
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.datepicker-booking-service {
  width: 20rem !important;
}
</style>
