<template>
  <b-overlay
    :show="stylistStore.isPending"
    variant="secondary"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="lg"
    class="min-vh-100"
  >
    <div
      id="user-profile"
    >
      <stylist-page-header :header-data="header" />
      <!-- Booking -->
      <section
        id="booking-service"
        class="pb-2 py-md-4 px-sm-2 px-lg-4"
      >
        <b-overlay
          :show="(saveAppointmentStore.isPending
            || blockDateTimeStore.isPending
            || customerLoggedStore.isPending
            || createCardStore.isPending
            || cardsList.isPending
          ) && !stylistStore.isPending"
          bg-color="#f8f8f8"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="lg"
        >
          <b-container>
            <b-row>
              <b-col
                lg="3"
                order-lg="2"
                class="second-column"
              >
                <booking-service-summary v-if="selectedServicesStore" />
              </b-col>
              <b-col
                lg="9"
                class="booking-fields-box"
              >
                <booking-service-extra-information />
              </b-col>
            </b-row>
          </b-container>
        </b-overlay>
      </section>
      <!--/ Booking  -->
    </div>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BOverlay, BContainer,
} from 'bootstrap-vue'
import StylistPageHeader from '@/components/stylist/stylist-page/StylistPageHeader.vue'
import { mapActions, mapState } from 'vuex'
import BookingServiceExtraInformation from '@/components/booking/booking-service/BookingServiceExtraInformation.vue'
import BookingServiceSummary from '@/components/booking/booking-service/BookingServiceSummary.vue'
import { BOOKING_FIRST_STEP_PAGE, BOOKING_SUCCESS_PAGE } from '@/router/routes/routes-names'
import CryptoJS from 'crypto-js'
import { AlertTriangleIcon } from 'vue-feather-icons'
import { TYPE } from 'vue-toastification'

/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BOverlay,
    StylistPageHeader,
    BookingServiceExtraInformation,
    BookingServiceSummary,
  },
  data() {
    return {
      header: {
        avatar: require('@/assets/images/user-uploads/stylist-profile/avatars/avatar-s-2.jpg'),
        businessName: null,
        coverImg: require('@/assets/images/user-uploads/stylist-profile/backgrounds/timeline.jpg'),
      },
    }
  },
  computed: {
    ...mapState('StylistPageStoreModule', {
      stylistStore: state => state.stylist,
    }),
    ...mapState('BookingServiceStoreModule', {
      selectedDateTimeStore: state => state.selectedDateTime,
      availableDatesStore: state => state.availableDates,
      saveAppointmentStore: state => state.saveAppointment,
      blockDateTimeStore: state => state.blockDateTime,
      selectedServicesStore: state => state.selectedServices,
      customerLoggedStore: state => state.customerLogged,
      createCardStore: state => state.createCard,
      cardsList: state => state.cardsList,
    }),
  },
  watch: {
    saveAppointmentStore: {
      deep: true,
      handler(newSaveAppointment) {
        if (newSaveAppointment.response) {
          this.$router.push({ name: BOOKING_SUCCESS_PAGE })
          this.$cookies.remove('booking_services')
          this.$cookies.remove('selected_datetime')
          this.$cookies.remove('reserved_book_date')
        }
        if (newSaveAppointment.error) {
          this.$toast('Error save appointment!', {
            icon: AlertTriangleIcon,
            type: TYPE.ERROR,
          })
        }
      },
    },
    stylistStore: {
      deep: true,
      handler(newStylistData) {
        if (newStylistData.response) {
          this.header.businessName = newStylistData.response.businessName
        }
        if (newStylistData.error) {
          this.$toast('Error fetching stylist data!', {
            icon: AlertTriangleIcon,
            type: TYPE.ERROR,
          })
        }
      },
    },
    blockDateTimeStore: {
      deep: true,
      handler(newAvailableDatesStore) {
        if (newAvailableDatesStore.error) {
          if (newAvailableDatesStore.error.response.data.status === false) {
            this.$toast(newAvailableDatesStore.error.response.data.message, {
              icon: AlertTriangleIcon,
              type: TYPE.ERROR,
            })
            this.$router.push({ name: BOOKING_FIRST_STEP_PAGE })
            return
          }
          this.$toast('Error reservation date to book!', {
            icon: AlertTriangleIcon,
            type: TYPE.ERROR,
          })
        }
      },
    },
    customerLoggedStore: {
      deep: true,
      handler(newCustomerLogged) {
        if (newCustomerLogged.error) {
          this.$toast('Error fetching customer data!', {
            icon: AlertTriangleIcon,
            type: TYPE.ERROR,
          })
        }
      },
    },
  },
  created() {
    if (!this.$cookies.get('selected_datetime')
        || !this.$cookies.get('reserved_book_date')
        || !this.$cookies.get('booking_services')
    ) {
      this.$router.push({ name: BOOKING_FIRST_STEP_PAGE })
      return
    }
    const period = CryptoJS.AES.decrypt(this.$cookies.get('reserved_book_date'), process.env.VUE_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8)
    const selectedDate = CryptoJS.AES.decrypt(this.$cookies.get('selected_datetime'), process.env.VUE_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8)
    const selectedServices = JSON.parse(CryptoJS.AES.decrypt(this.$cookies.get('booking_services'), process.env.VUE_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8))
    this.setSelectedDateTime(selectedDate)
    this.blockSelectedDateTime(period)
    this.setSelectedServices(selectedServices)
  },
  mounted() {
    this.getHeaderData()
    this.fetchCustomerData()
  },
  methods: {
    ...mapActions('BookingServiceStoreModule', [
      'clearSelectedDateTime',
      'blockSelectedDateTime',
      'setSelectedDateTime',
      'setSelectedServices',
      'fetchCustomerData',
    ]),
    ...mapActions('StylistPageStoreModule', [
      'fetchPublicStylist',
    ]),
    getHeaderData() {
      if (this.stylistStore.response.businessName === null) {
        this.fetchPublicStylist(this.$store.getters['app/getSubdomain'])
      }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-profile.scss';
@media (max-width: 991.98px) {
  .booking-fields-box {
    margin-top: 2rem;
  }
}
</style>
<style lang="scss" scoped>
@media (max-width: 991.98px) {
#user-profile{
    padding-bottom: 6rem;
  }
}
</style>
